<template>
  <b-card title="Galerías">
    <b-row>
      <b-col md="6" class="my-1">
        <b-button variant="primary" :to="{ name: 'galeria-nuevo' }" class="mr-1">
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Nuevo</span>
        </b-button>
        <b-button @click="actualizarLista()" variant="secondary" class="btn-icon mr-1" >
          <feather-icon
            icon="RotateCcwIcon"
          />
        </b-button>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              @keyup.enter="onFiltered()"
              placeholder="Buscar.."
            />

          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="my-2">
        <b-overlay
          :show="loading"
        >
          <b-table class="sm" responsive="sm" 
              ref="selectableTable"
              selectable
              select-mode="single" 
              hover
              :small="true"
              :items="items" 
              :fields="fields"
              @row-selected="onRowSelected"
              show-empty
              empty-text="No se encontraron registros coincidentes"
              >
              <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <i class="feather icon-disc primary" />
                  </template>

                  <template v-else>
                    <i class="feather icon-circle" />
                  </template>
              </template>

              <template #cell(fotograficas)="data">
                {{ data.item.galeria_item_count }} Fotografías
              </template>

              <template #cell(accion)="data">
                <b-button
                  :to="{ name: 'galeria-editar', params: { id: data.item.id } }"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.bottom="'Editar'"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  @click="confirmar_eliminar(data.item.id)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.bottom="'Eliminar'"
                  class="btn-icon"
                  size="sm"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
          <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BCard, BCardText, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BDropdown, BDropdownItem, BOverlay, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
//import axios from 'axios'
export default {
    components: {
        BCard,
        BCardText,
        BTable,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BDropdown,
        BDropdownItem,
        BOverlay
    },
    directives: {
      Ripple,
      'b-tooltip': VBTooltip,
    },
    data(){
        return{
              items:[],
              fields: [
                    { key: 'published_at', label: 'Fecha Publicación', sortable: false },
                    { key: 'titulo', label: 'Titulo', sortable: false },
                    //{ key: 'descripcion', label: 'Descripcion', sortable: false },
                    { key: 'fotograficas', label: 'Fotografías', sortable: false },                    
                    { key: 'user.name', label: 'Autor', sortable: false },
                    'accion',
                ],
              selected: [],
              filter: '',
              filterOn: [],
              perPage: 10,
              pageOptions: [10, 25, 50],
              totalRows: 1,
              currentPage: 1,
              loading:false,
              item:[],
        }
    },
    created(){        
        this.loading=true       
        this.listar();
    },  
    watch: {
      currentPage: function() {
        if (!this.loading) {
          this.loading = true;
          this.listar();
        }
      },
      filter: function(val) {
        if (val == "" || val.length == 0) {
          this.loading=true
          this.listar();
        }
      },
    }, 
    methods: {
        onRowSelected(items) {
          this.selected = items
        },
        selectAllRows() {
          this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
          this.$refs.selectableTable.clearSelected()
        },
        selectThirdRow() {
          this.$refs.selectableTable.selectRow(2)
        },
        unselectThirdRow() {
          this.$refs.selectableTable.unselectRow(2)
        },
        onFiltered(filteredItems) {
          if (this.currentPage!=1) {
            this.currentPage=1;
          }else{
            this.loading=true
            this.listar();
          }
        },
        listar(){
            var url= '/admin/galeria?page='+this.currentPage + "&buscar=" + this.filter;
            axiosIns.get(url)
            .then(res => {
                this.items=res.data.data;
                this.totalRows = res.data.total
                this.loading=false
            })
            .catch(err =>{
                console.log(err);
            });
        },
        actualizarLista(){
          this.loading=true;
          this.listar();
        },
        confirmar_eliminar(id) {
          this.$swal({
            title: "Advertencia",
            text: "¿Estás seguro de que deseas eliminar esta publicación de forma permanente?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            customClass: {
              confirmButton: "btn btn-danger",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.eliminar(id);
            }
          });
        },
        eliminar(id) {
         axiosIns.delete('/admin/galeria/'+id)
          .then(res => {
              this.$bvToast.toast('Exito', {
                title: 'Se elimino correctamente',
                variant:'primary',
                solid: false,
              })
              this.actualizarLista();
          }) 
          .catch(err =>{
              console.log(err);
          });
        },
    },
}
</script>

<style lang="scss">

</style>